import React from "react";
import "./Footer.css";

function Footer() {
    return (
        <section className="Footer">
            {/* <span>Support</span> */}
            {/* &nbsp;|&nbsp; */}
            <a href="https://www.addvolt.com/en/terms-conditions/">
                Terms &amp; Conditions
            </a>
            &nbsp;|&nbsp;
            <a href="https://www.addvolt.com/en/quality-privacy-policy/">
                Quality and Privacy Policy
            </a>
        </section>
    );
}

export default Footer;

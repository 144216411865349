import React from "react";
import "./DocumentGroup.css";

const iconByType = {
    pdf: "fa-file-pdf",
    step: "fa-file",
    zip: "fa-file-archive",
    video: "fa-file-video",
    default: "fa-file",
};

const getIconByType = (type) => {
    if (type in iconByType) {
        return iconByType[type];
    }
    return iconByType.default;
};

function DocumentGroup({ document, handleSelectDocument }) {
    return (
        <div className="DocumentGroup">
            <div className="row container">
                <i
                    className={`file-icon far ${getIconByType(document.type)}`}
                />
                &nbsp;
                <p className="document-title">{document.name}</p>
                <div className="document-download">
                    {Object.keys(document.files).map((locale) => {
                        const documentFile = document.files[locale];
                        return (
                            <button
                                key={locale}
                                className="btn-download"
                                disabled={!documentFile}
                                onClick={() =>
                                    handleSelectDocument(documentFile)
                                }
                            >
                                {locale}
                            </button>
                        );
                    })}
                </div>
                {document.description && (
                    <p className="document-description">
                        {document.description}
                    </p>
                )}
            </div>
        </div>
    );
}

export default DocumentGroup;

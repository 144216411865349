import React from "react";
import VehicleTypeButton from "./VehicleTypeButton";
import "./SelectVehicle.css";

function SelectVehicle({ handleChangeStep }) {
    return (
        <div className="SelectVehicle">
            <div className="row title">Browse Manuals by Vehicle</div>
            <div className="row subtitle">
                Click on the vehicle type to access the desired manual:
            </div>
            <div className="row vehicle-type-list">
                {/* <VehicleTypeButton
                    vehicleType="VAN"
                    onClick={() =>
                        handleChangeStep({ step: 2, selectedVehicle: "van" })
                    }
                /> */}
                <VehicleTypeButton
                    vehicleType="TRUCK"
                    onClick={() =>
                        handleChangeStep({ step: 2, selectedVehicle: "truck" })
                    }
                />
                <VehicleTypeButton
                    vehicleType="SEMI-TRAILER"
                    onClick={() =>
                        handleChangeStep({
                            step: 2,
                            selectedVehicle: "semitrailer",
                        })
                    }
                />
            </div>
        </div>
    );
}

export default SelectVehicle;

const operationDocuments = {
    id: "Operation",
    title: "Operation",
    description: "",
    labels: [
        "basicInformation",
        "standardInstallation",
        "ecodriveIntegration",
        "frigoblockIntegration",
        "milkIntegration",
        "mitsubishiIntegration",
        "eCool",
    ],
    subcategories: [
        {
            id: "User Manuals for Rigid Vehicles",
            title: "User Manuals for Rigid Vehicles",
            description: "",
            target: "truck",
            labels: [
                "standardInstallation",
                "ecodriveIntegration",
                "frigoblockIntegration",
                "mitsubishiIntegration",
            ],
            documents: [
                {
                    name: "ADDM/ADDL Standard",
                    type: "pdf",
                    label: "standardInstallation",
                    files: {
                        PT: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_PT.pdf",
                        EN: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_EN.pdf",
                        DE: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_DE.pdf",
                        ES: "",
                        FR: "",
                        NL: "",
                    },
                },
                {
                    name: "ADDM/ADDL Integrated with Ecodrive",
                    type: "pdf",
                    label: "ecodriveIntegration",
                    files: {
                        PT: "",
                        EN: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_w_Ecodrive_EN.pdf",
                        DE: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_w_Ecodrive_DE.pdf",
                        ES: "",
                        FR: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_w_Ecodrive_FR.pdf",
                        NL: "",
                    },
                },
                {
                    name: "ADDM/ADDL Integrated with Frigoblock",
                    type: "pdf",
                    label: "frigoblockIntegration",
                    files: {
                        PT: "",
                        EN: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_w_Frigoblock_EN.pdf",
                        DE: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_w_Frigoblock_DE.pdf",
                        ES: "",
                        FR: "",
                        NL: "4_Operation/41_User_Manuals/User_Manual_Rigid_Vehicle_w_Frigoblock_NL.pdf",
                    },
                },
                // {
                //     name: "ADDM/ADDL Integrated with Mitsubishi",
                //     type: "pdf",
                //     label: "mitsubishiIntegration",
                //     files: {
                //         PT: "",
                //         EN: "",
                //         DE: "",
                //         ES: "",
                //         FR: "",
                //         NL: "",
                //     },
                // },
            ],
        },
        {
            id: "User Manuals for Drawbar",
            title: "User Manuals for Drawbar",
            description: "",
            target: "semitrailer",
            labels: [
                "standardInstallation",
                "ecodriveIntegration",
                "frigoblockIntegration",
                "milkIntegration",
                "mitsubishiIntegration",
            ],
            documents: [
                {
                    name: "ADDM/ADDL Standard",
                    type: "pdf",
                    label: "standardInstallation",
                    files: {
                        PT: "",
                        EN: "4_Operation/41_User_Manuals/User_Manual_SemiTrailer_Drawbar_EN.pdf",
                        DE: "4_Operation/41_User_Manuals/User_Manual_SemiTrailer_Drawbar_DE.pdf",
                        ES: "",
                        FR: "",
                        NL: "",
                    },
                },
                {
                    name: "ADDM/ADDL Integrated with Ecodrive",
                    type: "pdf",
                    label: "ecodriveIntegration",
                    files: {
                        PT: "",
                        EN: "4_Operation/41_User_Manuals/User_Manual_SemiTrailer_Drawbar_w_Ecodrive_EN.pdf",
                        DE: "4_Operation/41_User_Manuals/User_Manual_SemiTrailer_Drawbar_w_Ecodrive_DE.pdf",
                        ES: "",
                        FR: "",
                        NL: "",
                    },
                },
                // {
                //     name: "ADDM/ADDL Integrated with Frigoblock",
                //     type: "pdf",
                //     label: "frigoblockIntegration",
                //     files: {
                //         PT: "",
                //         EN: "",
                //         DE: "",
                //         ES: "",
                //         FR: "",
                //         NL: "",
                //     },
                // },
                {
                    name: "ADDM/ADDL Integrated with Milk Tank",
                    type: "pdf",
                    label: "milkIntegration",
                    files: {
                        PT: "",
                        EN: "4_Operation/41_User_Manuals/User_Manual_SemiTrailer_MilkTank_EN.pdf",
                        DE: "4_Operation/41_User_Manuals/User_Manual_SemiTrailer_MilkTank_DE.pdf",
                        ES: "",
                        FR: "",
                        NL: "",
                    },
                },
                // {
                //     name: "ADDM/ADDL Integrated with Mitsubishi",
                //     type: "pdf",
                //     label: "mitsubishiIntegration",
                //     files: {
                //         PT: "",
                //         EN: "",
                //         DE: "",
                //         ES: "",
                //         FR: "",
                //         NL: "",
                //     },
                // },
            ],
        },
        // {
        //     id: "User Manuals for eCool",
        //     title: "User Manuals for eCool",
        //     description: "",
        //     target: "semitrailer",
        //     labels: [
        //         'eCool',
        //     ],
        //     documents: [
        //         {
        //             name: "ADDM/ADDL eCool",
        //             type: "pdf",
        //             label: "eCool",
        //             files: {
        //                 PT: "",
        //                 EN: '4_Operation/41_User_Manuals/User_Manual_SemiTrailer_Drawbar_Ecool.pdf',
        //                 DE: "",
        //                 ES: "",
        //                 FR: "",
        //                 NL: "",
        //             },
        //         },
        //     ],
        // },
    ],
};

export default operationDocuments;

import React, { Component } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Wizard from "./Wizard";
import SelectVehicle from "./SelectVehicle";
import SelectManual from "./SelectManual";
import DocumentPreview from "./DocumentPreview";
import "./Menu.css";

function withRouter(Component) {
    return function WrappedComponent(props) {
        const location = useLocation()
        const navigate = useNavigate();
        return <Component {...props} location={location} navigate={navigate} />;
    }
}

class Menu extends Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(props.location.search);
        this.state = {
            step: parseInt(queryParams.get('step')) || 1,
            selectedVehicle: queryParams.get('selectedVehicle') || null,
            selectedDocument: queryParams.get('selectedDocument') || null
        };
    }
    
    handleChangeStep = (newState) => {
        this.setState(newState, () => {
            this.props.navigate({
                pathname: '',
                search: new URLSearchParams(this.state).toString()
            });
        });
    };

    render() {
        return (
            <section className="Menu">
                <Wizard
                    step={this.state.step}
                    handleChangeStep={this.handleChangeStep}
                />
                {this.state.step === 1 && (
                    <SelectVehicle handleChangeStep={this.handleChangeStep} />
                )}
                {this.state.step === 2 && (
                    <SelectManual
                        handleChangeStep={this.handleChangeStep}
                        vehicleType={this.state.selectedVehicle}
                    />
                )}
                {this.state.step === 3 && (
                    <DocumentPreview
                        selectedDocument={this.state.selectedDocument}
                    />
                )}
            </section>
        );
    }
}

export default withRouter(Menu);

import React from "react";
import "./VehicleTypeButton.css";
import VanIcon from "./Icons/van.svg";
import TruckIcon from "./Icons/truck.svg";
import SemitrailerIcon from "./Icons/semitrailer.svg";

const ICON_BY_TYPE = {
    VAN: VanIcon,
    TRUCK: TruckIcon,
    "SEMI-TRAILER": SemitrailerIcon,
};

const getIconByType = (vehicleType) => {
    if (vehicleType in ICON_BY_TYPE) {
        return ICON_BY_TYPE[vehicleType];
    }
    return null;
};

function VehicleTypeButton({ vehicleType, onClick }) {
    const iconContent = getIconByType(vehicleType);
    return (
        <div className="VehicleTypeButton" onClick={onClick}>
            <img src={iconContent} alt={`${vehicleType} Icon`} />
            {vehicleType}
        </div>
    );
}

export default VehicleTypeButton;

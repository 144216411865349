import React from "react";
import "./Header.css";

function Header() {
    return (
        <section className="Header">
            <a href="/">
                <img
                    className="responsive"
                    alt="AddVolt Logo"
                    src="https://addvolt-resources.s3.eu-central-1.amazonaws.com/addvolt-logo-w.png"
                />
            </a>
        </section>
    );
}

export default Header;

import React from "react";
import "./StepNumber.css";

function StepNumber({ number, isActive }) {
    return (
        <span className={isActive ? "StepNumber active" : "StepNumber"}>
            {number}
        </span>
    );
}

export default StepNumber;

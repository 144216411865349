import React from "react";
import "./SelectManual.css";
import DocumentList from "../../../constants";
import DocumentGroup from "./DocumentGroup";

function SelectManual({ vehicleType, handleChangeStep }) {
    const manualsSpecification = DocumentList.subcategories.filter(
        (category) => category.target === vehicleType
    ) || { documents: [] };
    if (manualsSpecification.length === 0) {
        return (
            <div className="SelectManual">
                <div className="row subtitle">
                    There are no manuals available for the selected vehicle
                    type.
                </div>
            </div>
        );
    }

    return (
        <div className="SelectManual">
            <div className="row title">Select Manual</div>
            <div className="row subtitle">
                Click on the preferred language to access the manual.
            </div>
            {
                manualsSpecification.map((section) =>
                    <div className="row container">
                        <p className="subcategory-title">
                            {section.title}
                        </p>
                        {section.documents.map((document) => (
                            <DocumentGroup
                                key={document.name}
                                document={document}
                                handleSelectDocument={(document) =>
                                    handleChangeStep({
                                        step: 3,
                                        selectedDocument: document,
                                    })
                                }
                            />
                        ))}
                    </div>
                )
            }
        </div>
    );
}

export default SelectManual;

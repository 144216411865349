import React from "react";
import StepNumber from "./StepNumber";
import "./Wizard.css";

function Wizard({ step, handleChangeStep }) {
    return (
        <div className="Wizard">
            <ul>
                <li
                    className={`WizardStep ${step === 1 ? "active" : ""}`}
                    onClick={() => handleChangeStep({ step: 1, selectedVehicle: null, selectedDocument: null })}
                >
                    <StepNumber number={1} isActive={step >= 1} />
                    &nbsp;&nbsp;Select Vehicle
                </li>
                <li
                    // Step2 is disabled when Step1 is active, since we don't know which documents to show
                    // until we know the vehicle type.
                    className={`WizardStep ${step === 2 ? "active" : ""} ${
                        step === 1 ? "disabled" : ""
                    }`}
                    onClick={() => step === 3 && handleChangeStep({ step: 2, selectedDocument: null })}
                >
                    <StepNumber number={2} isActive={step >= 2} />
                    &nbsp;&nbsp;Select Manual
                </li>
                <li
                    // Step3 is disabled until we know which vehicle type and which manual the user is requesting.
                    className={`WizardStep ${
                        step === 3 ? "active" : "disabled"
                    }`}
                >
                    <StepNumber number={3} isActive={step === 3} />
                    &nbsp;&nbsp;Preview Manual
                </li>
            </ul>
        </div>
    );
}

export default Wizard;

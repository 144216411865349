import "./App.css";
import Header from "../Header";
import Footer from "../Footer";
import Menu from "../Menu";

function App() {
    return (
        <div className="App">
            <Header />
            <Menu />
            <Footer />
        </div>
    );
}

export default App;
